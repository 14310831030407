
import { computed, defineComponent, onMounted, ref } from 'vue'
import { Employee } from '@/store/modules/EmployeeModule'
import { useStore } from 'vuex'
import DataTable from '@/components/mc-admin-datatable/DataTable.vue'
import { ActivitySessionFilter } from '@/store/modules/ActivitySessionModule'
import { Actions, MutationsActions } from '@/store/enums/StoreEnums'
import {
  formatListDate,
  formatListHourInterval
} from '@/core/mc-admin-helpers/activity-session'
import { getEngagementColor, getConfidenceColorAndLabel, getSkillColor } from '@/core/mc-admin-helpers/statistics'
import { MetricsPayload } from '@/store/modules/StatisticModule'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'recent-activities',
  components: {
    DataTable
  },
  setup () {
    const store = useStore()
    const router = useRouter()
    const userUuid = router.currentRoute.value.params.id as string
    const employee = computed(() => store.getters.getEmployee) as unknown as Employee
    const tableHeader = ref([
      {
        name: 'NAME',
        key: 'name',
        sortable: true
      },
      {
        name: 'Date',
        key: 'date',
        sortable: true
      },
      {
        name: 'Engagement',
        key: 'engagement',
        sortable: false
      },
      {
        name: 'Confidence',
        key: 'confidence',
        sortable: false
      },
      {
        name: 'Forecast',
        key: 'skill',
        sortable: false
      },
      {
        name: '',
        key: 'actions',
        sortable: false
      }
    ])
    const tableSearch = ref<string>('')
    const itemsPerPage = ref<number>(5)
    const selectedPage = ref<number>(1)
    const tableData = ref(computed(() => store.getters.getEmployeeStatisticsForSessions))
    const tableLoading = computed(() => store.getters.getActivitySessionRequestPending)
    const sessionCount = computed(() => store.getters.getActivitySessionsCount)
    const tablePagesCount = computed(() => Math.ceil(sessionCount.value / itemsPerPage.value))

    const onCurrentPageChange = (page) => {
      selectedPage.value = page
      const filter: MetricsPayload = {
        metrics: ['employee_statistics_for_sessions'],
        filter: {
          employees: [userUuid],
          status: 4,
          order: 'start_date',
          offset: itemsPerPage.value * (page - 1),
          limit: itemsPerPage.value
        }
      }
      if (tableSearch.value !== '') {
        filter.filter.name = tableSearch.value
      }
      store.dispatch(Actions.API_GET_METRICS_BY_FILTER, filter)
    }

    const onItemsPerPageChange = (rowsPerPage) => {
      itemsPerPage.value = rowsPerPage
      const filter: MetricsPayload = {
        metrics: ['employee_statistics_for_sessions'],
        filter: {
          employees: [userUuid],
          status: 4,
          order: 'start_date',
          offset: rowsPerPage * (selectedPage.value - 1),
          limit: rowsPerPage
        }
      }
      if (tableSearch.value !== '') {
        filter.filter.name = tableSearch.value
      }
      store.dispatch(Actions.API_GET_METRICS_BY_FILTER, filter)
    }

    let timeoutId = 0 as any
    const onItemsSearch = () => {
      clearTimeout(timeoutId)
      const filter: MetricsPayload = {
        metrics: ['employee_statistics_for_sessions'],
        filter: {
          employees: [userUuid],
          status: 4,
          name: tableSearch.value,
          order: 'start_date',
          offset: 0,
          limit: itemsPerPage.value
        }
      }
      const countPayload: ActivitySessionFilter = {
        filter: {
          name: tableSearch.value,
          status: 4,
          employeeUuid: employee.userUuid
        }
      }
      timeoutId = setTimeout(() => {
        store.dispatch(Actions.API_GET_METRICS_BY_FILTER, filter)
        store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_SESSIONS_COUNT, countPayload)
      }, 500)
    }

    onMounted(() => {
      const filter: MetricsPayload = {
        metrics: ['employee_statistics_for_sessions'],
        filter: {
          employees: [userUuid],
          status: 4,
          order: 'start_date',
          offset: 0,
          limit: itemsPerPage.value
        }
      }
      store.dispatch(Actions.API_GET_METRICS_BY_FILTER, filter)
      const countPayload: ActivitySessionFilter = { filter: { status: 4, employeeUuid: userUuid } }
      store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_SESSIONS_COUNT, countPayload)
    })

    return {
      employee,
      tableData,
      tableHeader,
      tableSearch,
      tablePagesCount,
      tableLoading,
      itemsPerPage,
      selectedPage,
      onCurrentPageChange,
      onItemsPerPageChange,
      onItemsSearch,
      formatListDate,
      formatListHourInterval,
      getEngagementColor,
      getConfidenceColorAndLabel,
      getSkillColor
    }
  }
})
