export const getEngagementColor = (engagement) => {
  engagement = parseFloat(engagement)
  let skillColor = ''
  switch (true) {
    case (engagement >= 4): {
      skillColor = '#88c794'
      break
    }
    case (engagement >= 2): {
      skillColor = '#e9b44d'
      break
    }
    default: {
      skillColor = '#cd5a6f'
      break
    }
  }
  return skillColor
}

export const getConfidenceColorAndLabel = (confidence) => {
  confidence = parseFloat(confidence)
  let confidenceColor = ''
  let confidenceLevel = ''
  switch (true) {
    case (confidence >= 4): {
      confidenceColor = '#cd5a6f'
      confidenceLevel = 'Overconfident'
      break
    }
    case (confidence <= -4): {
      confidenceColor = '#cd5a6f'
      confidenceLevel = 'Underconfident'
      break
    }
    case (confidence >= 2): {
      confidenceColor = '#e9b44d'
      confidenceLevel = 'Overconfident'
      break
    }
    case (confidence <= -2): {
      confidenceColor = '#e9b44d'
      confidenceLevel = 'Underconfident'
      break
    }
    default: {
      confidenceColor = '#88c794'
      confidenceLevel = 'Confident'
      break
    }
  }
  return { color: confidenceColor, label: confidenceLevel }
}

export const getSkillColor = (skill) => {
  skill = parseFloat(skill)
  let skillColor = ''
  switch (true) {
    case (skill >= 80): {
      skillColor = '#88c794'
      break
    }
    case (skill >= 50): {
      skillColor = '#e9b44d'
      break
    }
    default: {
      skillColor = '#cd5a6f'
      break
    }
  }
  return skillColor
}
