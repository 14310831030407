import moment from 'moment'

export const getStatusBadge = (activitySessionStatus: number): Object => {
  switch (activitySessionStatus) {
    case 1: {
      return {
        class: 'houston-badge-steel',
        label: 'Draft'
      }
    }
    case 2: {
      return {
        class: 'houston-badge-primary',
        label: 'Scheduled'
      }
    }
    case 3: {
      return {
        class: 'houston-badge-warning',
        label: 'Ongoing'
      }
    }
    case 4: {
      return {
        class: 'houston-badge-success',
        label: 'Finished'
      }
    }
    case 5: {
      return {
        class: 'houston-badge-danger',
        label: 'Cancelled'
      }
    }
    case 6: {
      return {
        class: 'houston-badge-pending',
        label: 'Deploying ...'
      }
    }
    default: {
      return {
        class: 'houston-badge-steel',
        label: 'Draft'
      }
    }
  }
}

export const formatListDate = (apiDate: string): string => {
  return moment(String(apiDate)).format('MMMM Do, YYYY')
}

export const formatListHourInterval = (startDate: string, endDate): string => {
  const start = moment(String(startDate)).format('H:mm')
  const end = moment(String(endDate)).format('H:mm')

  return start + ' - ' + end
}
